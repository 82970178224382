// Packages
import React, { Fragment, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Components
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import Layout from '../../components/Layout/Layout/Layout';
import PageHeader from '../../components/UI/PageHeader/PageHeader';
import ContactUsBanner from './ContactUsBanner/ContactUsBanner';
import ContactInformation from './ContactInformation/ContactInformation';
import FeaturedPartners from '../../components/Featured/FeaturedPartners/FeaturedPartners';
import FeaturedCorporateCustomers from '../../components/Featured/FeaturedCorporateCustomers/FeaturedCorporateCustomers';
import FeaturedGovernmentClients from '../../components/Featured/FeaturedGovernmentClients/FeaturedGovernmentClients';

// API
import { getProductTypes, getPartners, getCustomers } from '../../api/request';

// CSS
import './ContactUsPage.css';

const ContactUsPage = () => {
  // State
  const [productTypes, setProductTypes] = useState();
  const [partners, setPartners] = useState();
  const [governmentClients, setGovernmentClients] = useState();
  const [corporateClients, setCorporateClients] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!productTypes) dataProductTypes();
    if (!partners) dataPartners();
    if (!governmentClients || !corporateClients) dataClients();
  }, []);

  // Get product types
  const dataProductTypes = async () => {
    const data = await getProductTypes();
    setProductTypes(data);
    setTimeout(() => setIsLoading(false), 500);
  };

  // Get partners
  const dataPartners = async () => {
    const data = await getPartners();
    setPartners(data);
    setTimeout(() => setIsLoading(false), 500);
  };

  // Get customers
  const dataClients = async () => {
    const data = await getCustomers();
    let governmentClientsList =
      data &&
      data.filter((customer) => {
        return customer.type === 'GOVERNMENT';
      });
    let corporateClientsList =
      data &&
      data.filter((customer) => {
        return customer.type === 'CORPORATE';
      });
    setGovernmentClients(governmentClientsList);
    setCorporateClients(corporateClientsList);
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      {isLoading && <LoadingScreen />}
      {!isLoading && (
        <Layout>
          <Container fluid>
            <PageHeader title='Customer Support and Hotline' />
            <ContactUsBanner />
            <ContactInformation />
            {partners && partners.length > 0 && <FeaturedPartners partners={partners} />}
            {corporateClients && corporateClients.length > 0 && (
              <FeaturedCorporateCustomers corporateClients={corporateClients} />
            )}
            {governmentClients && governmentClients.length > 0 && (
              <FeaturedGovernmentClients governmentClients={governmentClients} />
            )}
          </Container>
        </Layout>
      )}
    </Fragment>
  );
};

export default ContactUsPage;
